exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-atrakcje-tsx": () => import("./../../../src/pages/atrakcje.tsx" /* webpackChunkName: "component---src-pages-atrakcje-tsx" */),
  "component---src-pages-domek-duzy-tsx": () => import("./../../../src/pages/domek-duzy.tsx" /* webpackChunkName: "component---src-pages-domek-duzy-tsx" */),
  "component---src-pages-domek-maly-tsx": () => import("./../../../src/pages/domek-maly.tsx" /* webpackChunkName: "component---src-pages-domek-maly-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-regulamin-tsx": () => import("./../../../src/pages/regulamin.tsx" /* webpackChunkName: "component---src-pages-regulamin-tsx" */)
}

